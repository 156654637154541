import type { OSizerPanel, SizerControl, ISizerStorageItem } from 'o365-ui-components'
import type DataGridControl from './DataGridControl.ts'
import { localStorageHelper, MessageFunction, MasterMessageChannel } from 'o365-modules';
import { addEventListener } from 'o365-vue-utils';
import { nextTick } from 'vue';

/**
 * Control responsible for the grid sidepanel menu
 */
export default class DataGridMenuControl {
    /** DataGridControl this menu belongs to */
    private _dataGridControl: DataGridControl;


    /** The currently active tab */
    activeTab: DataGridMenuTabs | null = null;
    /** Last active tab set by user */
    private _lastActiveTab: DataGridMenuTabs | null = null;
    private _getTabsControl: () => any;

    /** Show the groupby folders tab */
    groupByFoldersTabEnabled: boolean = false;
    /** Show the layouts tab */
    layoutsTabEnabled: boolean = false;
    /** Show the detials component/iframe tab */
    detailsTabEnabled: boolean = false;

    /** Determines if tab content should be rendered */
    visible: boolean = false;

    /** The id of the sizer element */
    sizerContainerId: string;

    /** The sizer control */
    sizerControl?: SizerControl;
    /**The sizer component ref for getting the raw sizer control */
    sizerPanelRef?: InstanceType<typeof OSizerPanel>;

    /** The title for the details iframe tab/sub-tab. Is set on iframe loads */
    detailsIframeTitle: string;

    /** The current state of the menu (expanded, collapsed left, collapsed right) */
    sizerState?: DataGridMenuSizerState;

    /** Sizer state cache used when switching sides to sync them up */
    sizerStateCache?: ISizerStorageItem;

    /** Message channel for iframe */
    messageChannel: MasterMessageChannel | null = null;

    /** Side panel is appended on the left side of the grid */
    leftSidepanel: boolean;

    /** Last loaded iframe element */
    iframe?: HTMLElement;

    /** SidePanel collapse keybind handler */
    private _collapseKeybindHandler: (() => void) | null = null;

    /**
     * The side panel sizer state mapped to the menu state based on 
     * if the panel is on the left or right side
     */
    get menuState() {
        if (this.leftSidepanel) {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedLeft:
                    return DataGridMenuState.GridExpanded;
                case DataGridMenuSizerState.CollapsedRight:
                    return DataGridMenuState.MenuExpanded;
                case DataGridMenuSizerState.Expanded:
                    return DataGridMenuState.BothExpanded;
            }
        } else {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedLeft:
                    return DataGridMenuState.MenuExpanded;
                case DataGridMenuSizerState.CollapsedRight:
                    return DataGridMenuState.GridExpanded;
                case DataGridMenuSizerState.Expanded:
                    return DataGridMenuState.BothExpanded;
            }
        }
        return undefined;
    }

    get gridProps() {
        return this._dataGridControl.props;
    }

    /**
     * Width of the side panel. Only tracked after finishing resizing
     */
    sidepanelWidth: number | null = null;

    private get _localKey() {
        return `${this._dataGridControl.id}_sidemenutab`
    }

    /** Default tab that should be active */
    private get _defaultTab() {
        if (this._dataGridControl.props.verticalTabs && this._dataGridControl.props.menuTabs?.length) {
            return this._dataGridControl.props.menuTabs.find(x => x.active)?.id;
        }
        if (this.detailsTabEnabled) {
            return DataGridMenuTabs.Details
        } else if (this._dataGridControl.props.hideMenuItems) {
            if (this._dataGridControl.props.hideMenuItems.includes('filters')) {
                if (this._dataGridControl.props.hideMenuItems.includes('columns')) {
                    if (this._dataGridControl.props.hideMenuItems.includes('export')) {
                        if (this._dataGridControl.props.hideMenuItems.includes('layouts')) {
                            throw new Error('Cannot determine default tab since all menu items are hidden. Please use hideGridMenu');
                        } else {
                            return DataGridMenuTabs.Layouts
                        }
                    } else {
                        return DataGridMenuTabs.Export
                    }
                } else {
                    return DataGridMenuTabs.Columns
                }
            } else {
                return DataGridMenuTabs.Filters
            }
        } else {
            return DataGridMenuTabs.Filters
        }
    }


    constructor(options: {
        dataGridControl: DataGridControl,
        enableGroupByFolders: boolean,
        initialVisible: boolean,
        enableDetails: boolean,
        detailTabTitle: string,
        messageChannelId?: string,
        messageChannelFunctions?: Record<string, MessageFunction>,
        leftSidepanel?: boolean
        getTabsControl: () => any
    }) {
        this._dataGridControl = options.dataGridControl;

        if (options.enableGroupByFolders) {
            this.groupByFoldersTabEnabled = true;
        }
        if (this._dataGridControl.dataObject?.layoutManager) {
            this.layoutsTabEnabled = true;
        }
        if (options.enableDetails) {
            this.detailsTabEnabled = true;
        }
        this.detailsIframeTitle = options.detailTabTitle ?? 'Detail';

        if (options.initialVisible) {
            this.visible = true;
        }

        this.leftSidepanel = options.leftSidepanel ?? true;

        this._getTabsControl = options.getTabsControl;

        this.sizerContainerId = `${this._dataGridControl.id}_sidepanel`;
        if (options.messageChannelId) {
            this.messageChannel = new MasterMessageChannel({
                id: options.messageChannelId,
                functions: options.messageChannelFunctions,
                connectDelay: 300,
            });
        }

        if (this.visible) {
            this.activeTab = this._defaultTab;
        }
    }

    /** Initialization logic called after menu main component mount */
    async mountedInitialize(pOptions: {
        bootstrapTransitionPromise?: { value?: Promise<void> },
        sizerState?: ISizerStorageItem,
        previousActiveTab?: DataGridMenuTabs
    }) {
        this.sizerPanelRef?.sizerPanel?.querySelectorAll('.width-sizer .close-sizer')?.forEach(el => el.classList.add('d-none'));
        if (!this.visible) {
            if (pOptions.bootstrapTransitionPromise?.value) {
                await pOptions.bootstrapTransitionPromise?.value;
            }
        }
        this.handleSizerCollapse(null);

        if (!this.visible && ((this.sizerState === DataGridMenuSizerState.Expanded) || (this.leftSidepanel
            ? (this.sizerState === DataGridMenuSizerState.CollapsedRight)
            : (this.sizerState === DataGridMenuSizerState.CollapsedLeft)))) {
            this.visible = true;
        }

        // const storedActiveTab = this._getStoredTab();
        const storedActiveTab = null;
        if (storedActiveTab) {
            this.activeTab = storedActiveTab;
        } else if (this.visible && (this.leftSidepanel ? this.sizerState !== DataGridMenuSizerState.CollapsedLeft : this.sizerState !== DataGridMenuSizerState.CollapsedRight)) {
            this.activeTab = this._defaultTab;
        }
        this._lastActiveTab = this.activeTab;

        if (pOptions.sizerState && this.sizerControl) {
            const newState = this.sizerControl.inverseState(pOptions.sizerState);
            this.sizerControl.setState(newState, true);
            this.handleSizerCollapse(null);
            if (this.menuState === DataGridMenuState.BothExpanded || this.menuState === DataGridMenuState.MenuExpanded) {
                if (pOptions.previousActiveTab) {
                    this.setActiveTab(pOptions.previousActiveTab, true);
                } else {
                    this.activeTab = null
                }
            } else {
                this.activeTab = null;
            }
        }

        if (this._collapseKeybindHandler == null && this._dataGridControl.container) {
            this.containerInitialized();
        } 
    }

    /**
     * Set tab to active
     */
    setActiveTab(tab: DataGridMenuTabs, skipCollapse = false) {
        if (!this.visible) { this.visible = true; }
        if (skipCollapse) {
            this.activeTab = tab;
        } else if (this.leftSidepanel) {
            if (this.activeTab === tab && this.sizerState !== DataGridMenuSizerState.CollapsedLeft) {
                this.sizerOperation('left');
                this.activeTab = null;
            } else {
                if (this.sizerState === DataGridMenuSizerState.CollapsedLeft) {
                    this.sizerOperation('right');
                }
                this.activeTab = tab;
            }
        } else {
            if (this.activeTab === tab && this.sizerState !== DataGridMenuSizerState.CollapsedRight) {
                this.sizerOperation('right');
                this.activeTab = null;
            } else {
                if (this.sizerState === DataGridMenuSizerState.CollapsedRight) {
                    this.sizerOperation('left');
                }
                this.activeTab = tab;
            }
        }
        if (this.activeTab) {
            this._lastActiveTab = this.activeTab;
        }
        this._storeActiveTab(this.activeTab);
        let tabControl = this._getTabsControl();

        const setActiveTab = () => {
            if (this.activeTab !== DataGridMenuTabs.Details && tabControl) {
                let id = '';
                switch (this.activeTab) {
                    case DataGridMenuTabs.Filters:
                        id = `${this.sizerContainerId}_filters`;
                        break;
                    case DataGridMenuTabs.Columns:
                        id = `${this.sizerContainerId}_columns`;
                        break;
                    case DataGridMenuTabs.Export:
                        id = `${this.sizerContainerId}_export`;
                        break;
                    case DataGridMenuTabs.Layouts:
                        id = `${this.sizerContainerId}_layouts`
                        break;
                    case DataGridMenuTabs.GroupByFolders:
                        id = `${this.sizerContainerId}_groupbyfolder`;
                        break;
                    default:
                        if (this._dataGridControl.props.verticalTabs) {
                            id = `${this.sizerContainerId}_${this.activeTab}`;

                        }
                }
                tabControl.setActiveTab(id);
            }
        };
        if (tabControl == null) {
            nextTick().then(() => {
                tabControl = this._getTabsControl();
                setActiveTab();
            }
            );
        }
        setActiveTab();
    }

    /**
     * Fully expand the menu, if already expanded then collapse by one step
     */
    async expandMenu() {
        if (!this.visible) { this.visible = true; }
        if (!this.leftSidepanel) {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedRight:
                    await this.sizerOperation('left');
                    await (new Promise(resolve => setTimeout(resolve, 300)));
                    await this.sizerOperation('left');
                    this.activeTab = this._lastActiveTab ?? this._defaultTab;
                    this._storeActiveTab(this.activeTab);
                    break;
                case DataGridMenuSizerState.CollapsedLeft:
                    await this.sizerOperation('right');
                    break;
                case DataGridMenuSizerState.Expanded:
                    await this.sizerOperation('left');
                    break;
            }
        } else {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedLeft:
                    await this.sizerOperation('right');
                    await (new Promise(resolve => setTimeout(resolve, 300)));
                    await this.sizerOperation('right');
                    this.activeTab = this._lastActiveTab ?? this._defaultTab;
                    this._storeActiveTab(this.activeTab);
                    break;
                case DataGridMenuSizerState.CollapsedRight:
                    await this.sizerOperation('left');
                    break;
                case DataGridMenuSizerState.Expanded:
                    await this.sizerOperation('right');
                    break;
            }
        }
    }

    /**
     * Fully expand the grid, if already expanded then collapse by one step
     */
    async expandGrid() {
        if (this.leftSidepanel) {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedRight:
                    await this.sizerOperation('left');
                    await this.sizerOperation('left');
                    this.activeTab = null;
                    break;
                case DataGridMenuSizerState.CollapsedLeft:
                    await this.sizerOperation('right');
                    if (!this.visible) { this.visible = true; }
                    this.activeTab = this._lastActiveTab ?? this._defaultTab;
                    this.setActiveTab(this.activeTab,true);
                    break;
                case DataGridMenuSizerState.Expanded:
                    await this.sizerOperation('left');
                    this.activeTab = null;
                    break;
            }
        } else {
            switch (this.sizerState) {
                case DataGridMenuSizerState.CollapsedRight:
                    await this.sizerOperation('left');
                    if (!this.visible) { this.visible = true; }
                    this.activeTab = this._lastActiveTab ?? this._defaultTab;
                    break;
                case DataGridMenuSizerState.CollapsedLeft:
                    await this.sizerOperation('right');
                    await this.sizerOperation('right');
                    this.activeTab = null;
                    break;
                case DataGridMenuSizerState.Expanded:
                    this.sizerOperation('right');
                    this.activeTab = null;
                    break;
            }
        }
        this._storeActiveTab(this.activeTab);
    }

    /** SizerPanel collapsed handler */
    handleSizerCollapse(_side: string | null) {
        if (!this.sizerControl) { return; }
        if (this.sizerControl.sizer.classList.contains('collapsedRight')) {
            this.sizerState = DataGridMenuSizerState.CollapsedRight;
        } else if (this.sizerControl.sizer.classList.contains('collapsedLeft')) {
            this.sizerState = DataGridMenuSizerState.CollapsedLeft;
        } else {
            this.sizerState = DataGridMenuSizerState.Expanded;
        }

        // if (this.leftSidepanel) {
        this._getSidepanelWidth();
        // }
    }

    /** OIframe loaded handler for details tab */
    handleIframeLoaded(loadedSrc: string, e: Event) {
        if (loadedSrc && !loadedSrc.includes('undefined') && e.target) {
            const iframe = e.target as HTMLIFrameElement;
            this.iframe = iframe;

            try {
                if (iframe?.contentWindow) {
                    iframe.contentWindow.__o365_allowLeavingWithChanges = true;
                }
            } catch (ex) {
                console.error(ex);
            }

            if (this.messageChannel?.initialized) {
                this.messageChannel.close();
            }
            this.messageChannel?.connect(iframe);
        } else if (this.messageChannel?.initialized) {
            this.messageChannel?.close();
        }
    }

    /** Enter fullscren on the menu. Sizer state must be in Expanded */
    fullscreenMenu() {
        if (this.sizerState === DataGridMenuSizerState.Expanded) {
            this.leftSidepanel
                ? this.sizerOperation('right')
                : this.sizerOperation('left');
        }
    }

    /** Exit fullscren on the menu. Sizer state must be in CollapsedRight */
    exitFullscreenMenu() {
        if (this.leftSidepanel) {
            if (this.sizerState === DataGridMenuSizerState.CollapsedRight) {
                this.sizerOperation('left');
            }
        } else {
            if (this.sizerState === DataGridMenuSizerState.CollapsedLeft) {
                this.sizerOperation('right');
            }
        }
    }

    /**
     * Move the sidepanel to the other side of the grid
     */
    async switchSides() {
        if (this.sizerControl) {
            const state = this.sizerControl.getState();
            this.sizerStateCache = state;
        }
        localStorageHelper.setItem('datagrid-left-sidemenu', `${!this.leftSidepanel}`, {
            global: false
        });
        this._dataGridControl.leftSidepanel = !this._dataGridControl.leftSidepanel
        await nextTick();
        const scroll = this._dataGridControl.container?.querySelector(this._dataGridControl._gridQuery(".o365-body-horizontal-scroll-viewport"))
        if (scroll) {
            scroll.scrollLeft = 1;
        }
    }

    updateSidepanelWidth() {
        this._getSidepanelWidth();
    }

    getParsedUrl(pHref: string) {
        const current = this._dataGridControl.dataObject.current;
        return pHref.replace(/\{{(.*?)\}}/g, (field) => encodeURI(current[field.substring(2, field.length - 2)]));
    }

    // --- Row navigation logic ---

    /** Helper function for scrolling in the grid */

    navigateRow(pBackwards = false) {
        const currentIndex = this._dataGridControl.dataObject?.currentIndex;
        if (currentIndex == null) { return; }
        const increment = pBackwards ? -1 : 1;
        this._dataGridControl.dataObject?.setCurrentIndex(currentIndex + increment);
        this._attemptGridScroll();
    }
    private _attemptGridScroll(pIndexOverride?: number) {
        if (this._dataGridControl.getVerticalScrollViewport == null
            || this._dataGridControl.dataObject == null) {
            return;
        }
        const viewport: HTMLElement | undefined = this._dataGridControl.getVerticalScrollViewport();
        if (viewport == null) { return; }

        const rowHeight = parseFloat(`${this._dataGridControl.props.rowHeight}`)

        if (pIndexOverride != null) {
            viewport.scrollTop = pIndexOverride * rowHeight;
            return;
        }

        const currentIndex = this._dataGridControl.dataObject.currentIndex;
        if (currentIndex == null) { return; }
        const startIndex = Math.round(viewport.scrollTop / rowHeight);
        const endIndex = startIndex + Math.round(viewport.clientHeight / 34);


        if (currentIndex + 2 > endIndex) {
            const position = (startIndex + 1) * rowHeight;
            viewport.scrollTop = position;
        } else if (currentIndex - 2 < startIndex) {
            const position = (startIndex - 1) * rowHeight;
            viewport.scrollTop = position;
        }
    }
    // ----------------------------

    /**
     * Collapse operations for collapsing the sizer left or right
     */
    private async sizerOperation(side: 'left' | 'right') {
        const sizer = this.sizerPanelRef?.getSizer();
        if (!sizer) { return; }

        sizer.addAnimationClasses();

        window.setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 301);
        if (side === 'left') {
            await sizer.left();
        } else {
            await sizer.right();
        }
        this.handleSizerCollapse(null);
    }

    /**
     * Get the active tab from local storage
     */
    // private _getStoredTab(): DataGridMenuTabs | null {
    //     try {
    //         const lastActiveTab = localStorageHelper.getItem(this._localKey) ?? null;
    //         return lastActiveTab as DataGridMenuTabs
    //     } catch (ex) {
    //         return null;
    //     }
    // }

    /**
     * Save the active tab to local storage
     */
    private _storeActiveTab(tabId: string | null) {
        if (tabId) {
            localStorageHelper.setItem(this._localKey, tabId);
        } else {
            localStorageHelper.removeItem(this._localKey);
        }
    }

    private _getSidepanelWidth() {
        return;
        // const sizer = this.sizerPanelRef?.getSizer();
        // if (sizer) {
        //     switch (this.menuState) {
        //         case DataGridMenuState.BothExpanded:
        //         case DataGridMenuState.MenuExpanded:
        //             window.setTimeout(() => {
        //                 this.sidepanelWidth = sizer.element.getBoundingClientRect().width;
        //             }, sizer.ANIMATION_DURATION);
        //             break;
        //         case DataGridMenuState.GridExpanded:
        //             this.sidepanelWidth = null;
        //             break;
        //     }
        // } else {
        //     this.sidepanelWidth = null;
        // }
    }

    containerInitialized() {
        if (this._collapseKeybindHandler == null) {
            this._collapseKeybindHandler = addEventListener(this._dataGridControl.container!, 'keydown', (e: KeyboardEvent) => this._handleSidePanelKeyBinds(e));
        }
    }

    private _handleSidePanelKeyBinds(e: KeyboardEvent) {
        if (e.altKey && e.shiftKey) {
            switch (e.code) {
                case 'ArrowLeft':
                    switch (this.sizerState) {
                        case DataGridMenuSizerState.CollapsedLeft:
                            break;
                        case DataGridMenuSizerState.CollapsedRight:
                            e.stopPropagation();
                            this.sizerOperation('left');
                            break;
                        default:
                            e.stopPropagation();
                            this.sizerOperation('left');
                    }
                    break;
                case 'ArrowRight':
                    switch (this.sizerState) {
                        case DataGridMenuSizerState.CollapsedLeft:
                            e.stopPropagation();
                            this.sizerOperation('right');
                            break;
                        case DataGridMenuSizerState.CollapsedRight:
                            break;
                        default:
                            e.stopPropagation();
                            this.sizerOperation('right');
                    }
                    break;
                default:
                    return;
            }
        }
    }

    getLastActiveTab() {
        return this._lastActiveTab ?? this._defaultTab;
    }
}

/**
 * Details sub-tab definition
 */
export interface IDataGridMenuTab {
    id: string,
    title: string,
    label: string,
    iconClass?: string
    active?: boolean,
    top?: boolean,
    component: any
}

/**
 * Available tabs in the grid menu sidepanel
 */
export enum DataGridMenuTabs {
    Details = 'details',
    Filters = 'filters',
    Columns = 'columns',
    Export = 'export',
    Layouts = 'layouts',
    GroupBy = 'groupby',
    GroupByFolders = 'groupbyfolders'
}

/**
 * Possible states of the grid menu sizer
 */
export enum DataGridMenuSizerState {
    CollapsedLeft = 'collapsedLeft',
    CollapsedRight = 'collapsedRight',
    Expanded = 'expanded'
};

/**
 * Possible states of the grid menu sidepanel
 */
export enum DataGridMenuState {
    MenuExpanded = 'menuExpanded',
    GridExpanded = 'gridExpanded',
    BothExpanded = 'expanded'
};
